<template>
  <div class="modal is-active" id="modallocked">
    <div class="modal-background"></div>
    <div class="modal-card-wrapper">
      <img
        class="splash-will-img"
        src="https://mylastwill.s3.amazonaws.com/static/img/home/resource/professional-checked-2-re.webp"
        alt="will professionally checked"
      />
      <img
        class="splash-glass"
        src="https://mylastwill.s3.amazonaws.com/static/img/home/resource/professional-checked-3-re.webp"
        alt="will professionally checked"
      />

      <div class="modal-card" id="advice-form">
        <section class="modal-card-advice-body">
          <h3>Checking In Progress</h3>
          <div>
            You will receive an email when your will is ready to download and
            sign (please allow 48 hours for us to check your will).
          </div>
        </section>
      </div>
      <div class="modal-below">
        <a href="/contact">Help</a>
        <a
          v-on:click="
            $router.push('draft_will').catch((error) => {
              console.log(error)
            })
          "
          href="javascript:void(0)"
          >View Draft</a
        >
        <a @click="$store.dispatch('logout')" href="#">Logout</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LockedModal'
}
</script>
