<template>
  <router-link
    v-bind:to="routerLink"
    tag="div"
    active-class="selected"
    v-bind:class="{ completed: completed }"
    class="menu-item-block"
    :id="id"
  >
    <div class="menu-item-bullet">
      <div class="menu-item-time">
        <span>{{ minutes }}</span
        ><br />min
      </div>
    </div>
    <div class="menu-item-content">
      <div class="tick-wrapper">
        <span></span>
      </div>
      <div class="tick-text">
        <span>completed</span>
      </div>
      <div class="overlay-box">
        <div
          v-if="tutorialText && show.tutorial && showTutorial"
          @click.stop="show.tutorial = false"
          class="tutorial-element tutorial-element-top show-tutorial"
        >
          <div class="tutorial-element-content">
            {{ tutorialText }}
          </div>
        </div>

        <div class="content">
          <h4>{{ editBeginText }}</h4>
          <div>{{ title }} Section</div>
        </div>
      </div>
      <img v-if="image" v-bind:alt="title" v-bind:src="image" />
      <h3>{{ title }}</h3>
      <p v-html="description"></p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'MainMenuItem',
  props: [
    'title',
    'description',
    'image',
    'completed',
    'minutes',
    'routerLink',
    'id',
    'tutorialText',
    'showTutorial'
  ],
  computed: {
    editBeginText() {
      if (this.completed) return 'Edit'
      return 'Begin'
    }
  },
  data() {
    return {
      show: {
        tutorial: true
      }
    }
  }
}
</script>
