<template>
  <div class="main-menu-start">
    <h2>{{ personalDetails.full_name || 'Welcome' }}</h2>
    <p>
      <template v-if="summary.percentageComplete !== 100"
      >Please complete the remaining {{ summary.sectionsIncomplete }} sections
        to view your Will.
      </template
      >
      <template v-else
      >All sections complete -
        <strong>You can now proceed to checkout</strong>.
      </template
      >
      <template v-if="showPartnerStatus">
        <template
            v-if="
              personalDetails.partner_full_name &&
              !personalDetails.partner_details
            "
        >
          The Mirror Will for {{ personalDetails.partner_full_name }} has not
          been started, (instructions will be sent to both of you).
        </template>
        <template
            v-if="
              personalDetails.partner_details &&
              personalDetails.partner_details.wills === 0
            "
        >
          The Mirror will for
          {{ personalDetails.partner_details.personal_details.full_name }} is
          in progress.
        </template>
        <template
            v-if="
              personalDetails.partner_details &&
              personalDetails.partner_details.wills > 0
            "
        >
          The Mirror will for
          {{ personalDetails.partner_details.personal_details.full_name }} is
          complete
        </template>
      </template>
    </p>
    <p v-if="lastSaved">Last Saved {{ timePast(lastSaved) }}</p>
  </div>
</template>

<script>
import { TextFormat } from '@/common/mixins/TextFormat'

export default {
  name: 'WillPartnerStatus',
  mixins: [TextFormat],
  computed: {
    personalDetails () {
      return this.$store.getters.personalDetails
    },
    lastSaved () {
      return this.$store.getters.details.last_saved
    },
    summary () {
      return this.$store.getters.progressSummary
    },
    showPartnerStatus () {
      return !!(this.personalDetails && this.personalDetails.partner_full_name && !this.$store.getters.partner_ex)
    }
  }
}
</script>

<style scoped lang="scss">
.main-menu-start {
  p {
    font-size: 16px;
  }

  h2 {
    margin-bottom: 20px;
  }
}
</style>
