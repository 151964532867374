<template>
  <div class="container-fluid bg-home">
    <div class="row">
      <div class="container">

        <section class="medium-padding80 col-lg-12">

          <WillPartnerStatus/>

          <MainMenuItem
              :id="'profile'"
              :showTutorial="!profile.profileCompleted && this.tempAccount"
              :tutorialText="text.recommend"
              v-bind:completed="profile && profile.profileCompleted"
              v-bind:description="
              profile.profileCompleted
                ? profileDescription
                : profileInitialDescription
            "
              v-bind:minutes="'4'"
              v-bind:routerLink="'/profile'"
              v-bind:title="'Profile'"
          >
          </MainMenuItem>

          <MainMenuItem
              v-if="children && children.childrenRequired"
              :id="'children'"
              :showTutorial="!children.childrenCompleted && this.tempAccount"
              :tutorialText="text.recommend"
              v-bind:completed="children.childrenCompleted"
              v-bind:description="
              children.childrenCompleted
                ? childrenDescription
                : childrenInitialDescription
            "
              v-bind:minutes="'2'"
              v-bind:routerLink="'/children'"
              v-bind:title="'Children'"
          >
          </MainMenuItem>
          <MainMenuItem
              :id="'executors'"
              :showTutorial="!executors.executorsCompleted && this.tempAccount"
              :tutorialText="text.recommend"
              v-bind:completed="executors.executorsCompleted"
              v-bind:description="
              executors.executorsCompleted
                ? executorsDescription
                : executorsInitialDescription
            "
              v-bind:minutes="'3'"
              v-bind:routerLink="'/executors/type_of'"
              v-bind:title="'Executors'"
          >
          </MainMenuItem>
          <MainMenuItem
              v-if="trustees && trustees.trusteesRequired"
              :id="'trustees'"
              :showTutorial="!trustees.trusteesCompleted && this.tempAccount"
              :tutorialText="text.recommend"
              v-bind:completed="trustees.trusteesCompleted"
              v-bind:description="
              trustees.trusteesCompleted
                ? trusteesDescription
                : trusteesInitialDescription
            "
              v-bind:minutes="'2'"
              v-bind:routerLink="'/trustees/executors_as_trustees'"
              v-bind:title="'Trustees'"
          >
          </MainMenuItem>
          <MainMenuItem
              v-if="guardians && guardians.guardiansRequired"
              :id="'guardians'"
              v-bind:completed="guardians.guardiansCompleted"
              v-bind:description="
              guardians.guardiansCompleted
                ? guardiansDescription
                : guardiansInitialDescription
            "
              v-bind:minutes="'2'"
              v-bind:routerLink="'/guardians'"
              v-bind:title="'Guardians'"
          >
          </MainMenuItem>
          <MainMenuItem
              id="distribution"
              :showTutorial="
              !distribution.distributionCompleted && this.tempAccount
            "
              :tutorialText="text.notRecommend"
              v-bind:completed="distribution.distributionCompleted"
              v-bind:description="
              distribution.distributionCompleted
                ? distributionDescription
                : distributionInitialDescription
            "
              v-bind:minutes="'5'"
              v-bind:routerLink="'/distribution'"
              v-bind:title="'Distribution'"
          >
          </MainMenuItem>
          <MainMenuItem
              :id="'gifts'"
              :showTutorial="!gifts.giftsCompleted && this.tempAccount"
              :tutorialText="text.notRecommend"
              v-bind:completed="gifts.giftsCompleted"
              v-bind:description="
              gifts.giftsCompleted ? giftsDescription : giftsInitialDescription
            "
              v-bind:minutes="'4'"
              v-bind:routerLink="'/gifts'"
              v-bind:title="'Gifts'"
          >
          </MainMenuItem>
          <MainMenuItem
              :id="'funeral'"
              v-bind:completed="
              funeralProgress && funeralProgress.funeralCompleted
            "
              v-bind:description="
              funeralProgress.funeralCompleted
                ? funeralDescription
                : funeralInitialDescription
            "
              v-bind:minutes="'4'"
              v-bind:routerLink="'/funeral'"
              v-bind:title="'Funeral'"
          >
          </MainMenuItem>
          <MainMenuItem
              v-if="pets.petsRequired"
              :id="'pets'"
              v-bind:completed="pets.petsCompleted"
              v-bind:description="
              pets.petsCompleted ? petsDescription : petsInitialDescription
            "
              v-bind:image="'https://mylastwill.s3.amazonaws.com/static/img/menu-pets.png'"
              v-bind:minutes="'2'"
              v-bind:routerLink="'/pets'"
              v-bind:title="'Pets'"
          >
          </MainMenuItem>
          <MainMenuItem
              :id="'assets'"
              :showTutorial="!assets.assetsCompleted && this.tempAccount"
              :tutorialText="text.recommend"
              v-bind:completed="assets.assetsCompleted"
              v-bind:description="
              assets.assetsCompleted
                ? assetsDescription
                : assetsInitialDescription
            "
              v-bind:minutes="'5'"
              v-bind:routerLink="'/assets'"
              v-bind:title="'Assets & Liabilities'"
          >
          </MainMenuItem>

          <div id="createWill" class="main-menu-end menu-item-block">
            <router-link
                :to="submitTo"
                class="js-nav btn btn--dark btn-large btn-arrow main-menu-end-btn btn-fixed"
                tag="a"
            >
              <div class="text">{{ submitText }}</div>
              <i class="fa fa-arrow-right"></i>
            </router-link>
          </div>
        </section>
      </div>
    </div>
    <LockedModal v-show="locked"></LockedModal>
    <HandoverNewModal
        v-if="showHandoverModal"
        :show="showHandoverModal"
        @close="handover = false"
    />
  </div>
</template>

<script>
import MainMenuItem from './MainMenuItem'
import HandoverNewModal from './HandoverNewModal'
import LockedModal from './LockedModal'

import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { sectionDescriptions } from '@/common/mixins/sectionDescriptions'
import WillPartnerStatus from '@/common/components/WillPartnerStatus'

export default {
  name: 'Overview',
  mixins: [willStringHelpers, sectionDescriptions],
  components: {
    WillPartnerStatus,
    MainMenuItem,
    LockedModal,
    HandoverNewModal
  },
  data () {
    return {
      locked: false,
      text: {
        recommend: 'We recommend completing this section with your client.',
        notRecommend:
            'We DO NOT recommend completing this section with your client.'
      }
    }
  },
  mounted () {
    if (!this.showHandoverModal && !this.tempAccount) this.scroll()
  },
  beforeRouteLeave (to, from, next) {
    if (to.path === this.submitTo) {
      this.$store.dispatch('saveAll')
      this.$root.$emit('saveAdviceRecommended')
    }
    this.$store.dispatch('updateProgress')
    next()
  },
  watch: {
    document: {
      deep: true,
      immediate: true,
      handler (value) {
        if (value) {
          this.locked = true
        } else {
          this.locked = false
        }
      }
    }
  },
  computed: {
    children () {
      return this.$store.getters.progressChildren
    },
    guardians () {
      return this.$store.getters.progressGuardians
    },
    trustees () {
      return this.$store.getters.progressTrustees
    },
    executors () {
      return this.$store.getters.progressExecutors
    },
    funeralProgress () {
      return this.$store.getters.progressFuneral
    },
    profile () {
      return this.$store.getters.progressProfile
    },
    gifts () {
      return this.$store.getters.progressGifts
    },
    pets () {
      return this.$store.getters.progressPets
    },
    assets () {
      return this.$store.getters.progressAssets
    },
    distribution () {
      return this.$store.getters.progressDistribution
    },
    document () {
      // allow resubmission if document is in an end status
      return this.$store.getters.documents.find(
          (doc) => doc.paid && !doc.checked && !doc.end_duplicate && !doc.end_lost && !doc.end_no_response && !doc.end_handover
      )
    },
    user () {
      return this.$store.getters.user
    },
    tempAccount () {
      return this.user && this.user.temp_account && this.user.change_password
    },
    submitText () {
      if (this.tempAccount) return 'Handover Account'
      return this.$store.getters.willUpdate ? 'Submit Updates' : 'Create Will'
    },
    submitTo () {
      if (this.tempAccount) return '/handover'
      return '/create_will'
    },
    showHandoverModal () {
      return !!(
          this.user &&
          this.user.broker &&
          !this.user.temp_account &&
          this.$store.getters.showIntroModals.handover
      )
    }
  },
  methods: {
    scroll () {
      var options = {
        container: 'body',
        easing: 'ease-in',
        offset: -160,
        force: true,
        cancelable: true,
        onStart: function (element) {
          // scrolling started
        },
        onDone: function (element) {
          // scrolling is done
        },
        onCancel: function () {
          // scrolling has been interrupted
        },
        x: false,
        y: true
      }
      var duration = 900
      var element

      if (!this.profile.profileCompleted) {
        element = 'profile'
      } else if (
          this.children.childrenRequired &&
          !this.children.childrenCompleted
      ) {
        element = 'children'
      } else if (!this.executors.executorsCompleted) {
        element = 'executors'
      } else if (
          this.trustees.trusteesRequired &&
          !this.trustees.trusteesCompleted
      ) {
        element = 'trustees'
      } else if (
          this.guardians.guardiansRequired &&
          !this.guardians.guardiansCompleted
      ) {
        element = 'guardians'
      } else if (!this.distribution.distributionCompleted) {
        element = 'distribution'
      } else if (!this.gifts.giftsCompleted) {
        element = 'gifts'
      } else if (!this.funeralProgress.funeralCompleted) {
        element = 'funeral'
      } else if (this.pets.petsRequired && !this.pets.petsCompleted) {
        element = 'pets'
      } else if (!this.assets.assetsCompleted) {
        element = 'assets'
      } else {
        element = 'createWill'
      }
      if (element) this.$scrollTo('#' + element, duration, options)
    }
  }
}
</script>
