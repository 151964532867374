<template>
  <SlideModal
    v-if="show"
    :buttonText="'Get Started'"
    @close="removeBrokerFromUser"
  >
    <template v-slot:banner>takes<br />15 minutes</template>
    <template v-slot:slide1>
      <div class="explainer-top final">
        <picture>
          <source
            type="image/webp"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Signing.webp
            "
          />
          <source
            type="image/png"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Signing.png
            "
          />
          <img
            src="https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Signing.png"
            alt="my last will"
          />
        </picture>
      </div>
      <div class="explainer-bottom display-flex">
        <div class="explainer-bottom-inner">
          <h3>Click ‘Create Will’ & Checkout</h3>
          <p>
            Finally, simply click the ‘Create Will’ button at the bottom and
            follow the checkout process.
          </p>
        </div>
      </div>
    </template>
    <template v-slot:slide2>
      <div class="explainer-top">
        <picture>
          <source
            type="image/webp"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Main-img.webp
            "
          />
          <source
            type="image/png"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Main-img.png
            "
          />
          <img
            src="https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Main-img.png"
            alt="my last will"
          />
        </picture>
      </div>
      <div class="explainer-bottom display-flex">
        <div class="explainer-bottom-inner">
          <h3>Welcome</h3>
          <p>
            Some of the information required to create your Will has been
            completed by
            <span>{{ user.broker.company_name }}</span> to finish the process
            please click next and follow the instructions provided. If you need
            any support please use the ‘Live Chat’ function (bottom right) or
            call 01785 338292.
          </p>
        </div>
      </div>
    </template>
    <template v-slot:slide3>
      <div class="explainer-top">
        <picture>
          <source
            type="image/webp"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-questions-img.webp
            "
          />
          <source
            type="image/png"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-questions-img.png
            "
          />
          <img
            src="https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-questions-img.png"
            alt="my last will"
          />
        </picture>
      </div>
      <div class="explainer-bottom display-flex">
        <div class="explainer-bottom-inner">
          <h3>Check Completed Sections</h3>

          <p>
            The following sections have been completed:
            <span v-html="completedSections"></span>. Please check the details
            in the summary to make sure the information is correct. If anything
            needs to be amended please click into the section to edit the
            details.
          </p>
        </div>
      </div>
    </template>
    <template v-slot:slide4>
      <div class="explainer-top">
        <picture>
          <source
            type="image/webp"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Checking.webp
            "
          />
          <source
            type="image/png"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Checking.png
            "
          />
          <img
            src="https://mylastwill.s3.amazonaws.com/static/img/home/resource/Welcome-Checking.png"
            alt="my last will"
          />
        </picture>
      </div>
      <div class="explainer-bottom display-flex">
        <div class="explainer-bottom-inner">
          <h3>Complete Remaining Sections</h3>
          <p>
            You still need to compete the following sections:
            <span v-html="uncompletedSections"></span>. To do this click the
            section and answer the questions.
          </p>
        </div>
      </div>
    </template>
  </SlideModal>
</template>

<script>
import SlideModal from '../../../common/ui/SlideModal'
import { http } from '@/services'

export default {
  name: 'HandoverNewModal',
  props: ['show'],
  components: {
    SlideModal
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    completedSections() {
      var string = ''
      if (this.$store.getters.progressProfile.profileCompleted)
        string += '<span>Profile</span> '
      if (this.$store.getters.progressChildren.childrenCompleted)
        string += '<span>Children</span> '
      if (this.$store.getters.progressExecutors.executorsCompleted)
        string += '<span>Executors</span> '
      if (this.$store.getters.progressTrustees.trusteesCompleted)
        string += '<span>Trustees</span> '
      if (this.$store.getters.progressGuardians.guardiansCompleted)
        string += '<span>Guardians</span> '
      if (this.$store.getters.progressFuneral.funeralCompleted)
        string += '<span>Funeral</span> '
      if (this.$store.getters.progressGifts.giftsCompleted)
        string += '<span>Gifts</span> '
      if (this.$store.getters.progressDistribution.distributionCompleted)
        string += '<span>Distribution</span> '
      if (this.$store.getters.progressPets.petsCompleted)
        string += '<span>Pets</span> '
      if (this.$store.getters.progressAssets.assetsCompleted)
        string += '<span>Assets</span> '
      var words = string.split(' ')
      var result = ''
      for (var i = 0; i < words.length - 1; i++) {
        if (i === words.length - 2 && words.length !== 2) {
          result += ' and ' + words[i]
        } else if (i !== words.length - 3 && i !== words.length - 2) {
          result += words[i] + ', '
        } else {
          result += words[i]
        }
      }
      return result
    },
    uncompletedSections() {
      var string = ''
      if (!this.$store.getters.progressProfile.profileCompleted)
        string += '<span>Profile</span> '
      if (!this.$store.getters.progressChildren.childrenCompleted)
        string += '<span>Children</span> '
      if (!this.$store.getters.progressExecutors.executorsCompleted)
        string += '<span>Executors</span> '
      if (!this.$store.getters.progressTrustees.trusteesCompleted)
        string += '<span>Trustees</span> '
      if (!this.$store.getters.progressGuardians.guardiansCompleted)
        string += '<span>Guardians</span> '
      if (!this.$store.getters.progressFuneral.funeralCompleted)
        string += '<span>Funeral</span> '
      if (!this.$store.getters.progressGifts.giftsCompleted)
        string += '<span>Gifts</span> '
      if (!this.$store.getters.progressDistribution.distributionCompleted)
        string += '<span>Distribution</span> '
      if (!this.$store.getters.progressPets.petsCompleted)
        string += '<span>Pets</span> '
      if (!this.$store.getters.progressAssets.assetsCompleted)
        string += '<span>Assets</span> '
      var words = string.split(' ')
      var result = ''
      for (var i = 0; i < words.length - 1; i++) {
        if (i === words.length - 2 && words.length !== 2) {
          result += ' and ' + words[i]
        } else if (i !== words.length - 3 && i !== words.length - 2) {
          result += words[i] + ', '
        } else {
          result += words[i]
        }
      }
      return result
    }
  },
  methods: {
    removeBrokerFromUser() {
      http
        .post('broker/api/v2/user_handover_remove_broker')
        .then((response) => {
          this.$store.commit('showIntroModals', { handover: false })
        })
        .catch((error) => {
          console.log(error.response.data)
        })
    }
  }
}
</script>

<style scoped></style>
