var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid bg-home"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"container"},[_c('section',{staticClass:"medium-padding80 col-lg-12"},[_c('WillPartnerStatus'),_c('MainMenuItem',{attrs:{"id":'profile',"showTutorial":!_vm.profile.profileCompleted && this.tempAccount,"tutorialText":_vm.text.recommend,"completed":_vm.profile && _vm.profile.profileCompleted,"description":_vm.profile.profileCompleted
              ? _vm.profileDescription
              : _vm.profileInitialDescription,"minutes":'4',"routerLink":'/profile',"title":'Profile'}}),(_vm.children && _vm.children.childrenRequired)?_c('MainMenuItem',{attrs:{"id":'children',"showTutorial":!_vm.children.childrenCompleted && this.tempAccount,"tutorialText":_vm.text.recommend,"completed":_vm.children.childrenCompleted,"description":_vm.children.childrenCompleted
              ? _vm.childrenDescription
              : _vm.childrenInitialDescription,"minutes":'2',"routerLink":'/children',"title":'Children'}}):_vm._e(),_c('MainMenuItem',{attrs:{"id":'executors',"showTutorial":!_vm.executors.executorsCompleted && this.tempAccount,"tutorialText":_vm.text.recommend,"completed":_vm.executors.executorsCompleted,"description":_vm.executors.executorsCompleted
              ? _vm.executorsDescription
              : _vm.executorsInitialDescription,"minutes":'3',"routerLink":'/executors/type_of',"title":'Executors'}}),(_vm.trustees && _vm.trustees.trusteesRequired)?_c('MainMenuItem',{attrs:{"id":'trustees',"showTutorial":!_vm.trustees.trusteesCompleted && this.tempAccount,"tutorialText":_vm.text.recommend,"completed":_vm.trustees.trusteesCompleted,"description":_vm.trustees.trusteesCompleted
              ? _vm.trusteesDescription
              : _vm.trusteesInitialDescription,"minutes":'2',"routerLink":'/trustees/executors_as_trustees',"title":'Trustees'}}):_vm._e(),(_vm.guardians && _vm.guardians.guardiansRequired)?_c('MainMenuItem',{attrs:{"id":'guardians',"completed":_vm.guardians.guardiansCompleted,"description":_vm.guardians.guardiansCompleted
              ? _vm.guardiansDescription
              : _vm.guardiansInitialDescription,"minutes":'2',"routerLink":'/guardians',"title":'Guardians'}}):_vm._e(),_c('MainMenuItem',{attrs:{"id":"distribution","showTutorial":!_vm.distribution.distributionCompleted && this.tempAccount,"tutorialText":_vm.text.notRecommend,"completed":_vm.distribution.distributionCompleted,"description":_vm.distribution.distributionCompleted
              ? _vm.distributionDescription
              : _vm.distributionInitialDescription,"minutes":'5',"routerLink":'/distribution',"title":'Distribution'}}),_c('MainMenuItem',{attrs:{"id":'gifts',"showTutorial":!_vm.gifts.giftsCompleted && this.tempAccount,"tutorialText":_vm.text.notRecommend,"completed":_vm.gifts.giftsCompleted,"description":_vm.gifts.giftsCompleted ? _vm.giftsDescription : _vm.giftsInitialDescription,"minutes":'4',"routerLink":'/gifts',"title":'Gifts'}}),_c('MainMenuItem',{attrs:{"id":'funeral',"completed":_vm.funeralProgress && _vm.funeralProgress.funeralCompleted,"description":_vm.funeralProgress.funeralCompleted
              ? _vm.funeralDescription
              : _vm.funeralInitialDescription,"minutes":'4',"routerLink":'/funeral',"title":'Funeral'}}),(_vm.pets.petsRequired)?_c('MainMenuItem',{attrs:{"id":'pets',"completed":_vm.pets.petsCompleted,"description":_vm.pets.petsCompleted ? _vm.petsDescription : _vm.petsInitialDescription,"image":'https://mylastwill.s3.amazonaws.com/static/img/menu-pets.png',"minutes":'2',"routerLink":'/pets',"title":'Pets'}}):_vm._e(),_c('MainMenuItem',{attrs:{"id":'assets',"showTutorial":!_vm.assets.assetsCompleted && this.tempAccount,"tutorialText":_vm.text.recommend,"completed":_vm.assets.assetsCompleted,"description":_vm.assets.assetsCompleted
              ? _vm.assetsDescription
              : _vm.assetsInitialDescription,"minutes":'5',"routerLink":'/assets',"title":'Assets & Liabilities'}}),_c('div',{staticClass:"main-menu-end menu-item-block",attrs:{"id":"createWill"}},[_c('router-link',{staticClass:"js-nav btn btn--dark btn-large btn-arrow main-menu-end-btn btn-fixed",attrs:{"to":_vm.submitTo,"tag":"a"}},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.submitText))]),_c('i',{staticClass:"fa fa-arrow-right"})])],1)],1)])]),_c('LockedModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.locked),expression:"locked"}]}),(_vm.showHandoverModal)?_c('HandoverNewModal',{attrs:{"show":_vm.showHandoverModal},on:{"close":function($event){_vm.handover = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }