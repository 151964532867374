var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-menu-start"},[_c('h2',[_vm._v(_vm._s(_vm.personalDetails.full_name || 'Welcome'))]),_c('p',[(_vm.summary.percentageComplete !== 100)?[_vm._v("Please complete the remaining "+_vm._s(_vm.summary.sectionsIncomplete)+" sections to view your Will. ")]:[_vm._v("All sections complete - "),_c('strong',[_vm._v("You can now proceed to checkout")]),_vm._v(". ")],(_vm.showPartnerStatus)?[(
            _vm.personalDetails.partner_full_name &&
            !_vm.personalDetails.partner_details
          )?[_vm._v(" The Mirror Will for "+_vm._s(_vm.personalDetails.partner_full_name)+" has not been started, (instructions will be sent to both of you). ")]:_vm._e(),(
            _vm.personalDetails.partner_details &&
            _vm.personalDetails.partner_details.wills === 0
          )?[_vm._v(" The Mirror will for "+_vm._s(_vm.personalDetails.partner_details.personal_details.full_name)+" is in progress. ")]:_vm._e(),(
            _vm.personalDetails.partner_details &&
            _vm.personalDetails.partner_details.wills > 0
          )?[_vm._v(" The Mirror will for "+_vm._s(_vm.personalDetails.partner_details.personal_details.full_name)+" is complete ")]:_vm._e()]:_vm._e()],2),(_vm.lastSaved)?_c('p',[_vm._v("Last Saved "+_vm._s(_vm.timePast(_vm.lastSaved)))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }